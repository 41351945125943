import {
  createRouter,
  createWebHashHistory,
} from 'vue-router'

const routes = [
  {

    path: '/',
    alias: '/main',
    name: 'main',
    component: () => import('../views/MainView.vue'),
  },
  {
    path: '/trailer',
    name: 'trailer',
    component: () => import('../views/TrailerView.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
  },
  {
    path: '/form',
    name: 'form',
    component: () => import('../views/FormClose.vue'),
  },
  {
    path: '/seats',
    name: 'seats',
    component: () => import('../views/SeatsView.vue'),
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
