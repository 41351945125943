export function getConsoleBanner() {
  const styleList = [
    'background-color: black',
    'background: linear-gradient(to right, #67b26f, #4ca2cd)',
    'border-radius: 4px',
    'padding: 20px',
    'color: white',
    'font-size: 28px',
    'font-weight: bold',
  ];
  const text = `Copyright © 2022 Mao & Tsou. All Rights Reserved.`;

  console.log(`%c ${ text } `, styleList.join(';'));
}

export function getGiphyMessage() {
  const styleList = [
    'background-image: url("https://media.giphy.com/media/f4HpCDvF84oh2/giphy.gif")',
    'background-size: cover',
    'color: #fff',
    'display: block',
    'padding: 80px',
    'line-height: 35px',
    'border: 5px solid black',
    'font-size: 14px',
    'font-weight: bold',
    'font-family: monospace',
  ];
  const messageList = [
    `I love you!`,
    `hellllllooooo~~~`,
    `It's good to see you here!`,
  ];
  const randomMessage = messageList[Math.floor(Math.random() * messageList.length)];

  console.log(`%c ${ randomMessage } `, styleList.join(';'));
}

export function insertAsciiArt() {
  /* eslint-disable */
  const string = `
    ███╗░░░███╗░█████╗░░█████╗░    ████████╗░██████╗░█████╗░██╗░░░██╗
    ████╗░████║██╔══██╗██╔══██╗    ╚══██╔══╝██╔════╝██╔══██╗██║░░░██║
    ██╔████╔██║███████║██║░░██║    ░░░██║░░░╚█████╗░██║░░██║██║░░░██║
    ██║╚██╔╝██║██╔══██║██║░░██║    ░░░██║░░░░╚═══██╗██║░░██║██║░░░██║
    ██║░╚═╝░██║██║░░██║╚█████╔╝    ░░░██║░░░██████╔╝╚█████╔╝╚██████╔╝
    ╚═╝░░░░░╚═╝╚═╝░░╚═╝░╚════╝░    ░░░╚═╝░░░╚═════╝░░╚════╝░░╚═════╝░
  `;
  /* eslint-enable */
  const comment = document.createComment(string);
  const headElem = document.head;
  headElem.insertBefore(comment, headElem.firstChild);
}
