import 'dotenv/config';

import { createApp } from 'vue'
import { createHead } from '@vueuse/head';
import App from './App.vue'
import router from './router'
import {
  getConsoleBanner,
  getGiphyMessage,
  insertAsciiArt,
} from '@/helpers/easterEgg.js';

import 'modern-normalize/modern-normalize.css';
import '@/sass/main.scss';

const head = createHead();

createApp(App)
  .use(router)
  .use(head)
  .mount('#app');

// suprise
getConsoleBanner();
getGiphyMessage();
insertAsciiArt();
