<script setup>
import { Head } from '@vueuse/head';
const BASE_URL = process.env.BASE_URL;
const siteName = 'Mao & Tsou';
const description = 'Mao & Tsou wedding website';
</script>

<template>
  <Head>
    <title>{{ siteName }}</title>
    <meta name="description" :content="description">
		<meta name="theme-color" content="#ffffff">
    <!-- Social -->
    <meta property="og:image" :content="`${ BASE_URL }og_image_trailer.jpg`">
    <meta property="og:image:type" content="image/jpeg">
    <meta property="og:image:width" content="1200">
    <meta property="og:image:height" content="630">
    <link rel="icon" :href="`${ BASE_URL }favicon.svg`">
    <link rel="mask-icon" :href="`${ BASE_URL }favicon.svg`" color="#000000">
    <link rel="apple-touch-icon" :href="`${ BASE_URL }apple-icon-180x180.png`">
		<link rel="manifest" :href="`${ BASE_URL }manifest.json`">
  </Head>
  <router-view/>
</template>

<style>
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

#app {
  position: relative;
}
</style>
